<template>
  <div>
    <!--begin::customer-->
    <h3 class="card-label">
      {{ $t('purchases_invoices.purchases_invoices') }}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>

    <b-alert v-if="is_confirmed" :show="true" variant="warning">
      <h5>{{ $t('this_invoice_cant_be_editing') }}</h5>
    </b-alert>

    <b-tabs content-class="mt-3" class="nav-custom-link">
      <b-tab :title="$t('basic_information')" @click="toggleTab('basic_information')">
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('purchases_invoices.title') }}</label>
                  <input type="text" v-model="data.title" class="form-control" :class="validation && validation.title ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.title" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.title[0] }}
                            </span>
                </div>
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('purchases_invoices.supplier') }}<span class="text-danger">*</span></label>
                  <div class="form-group row">
                    <div class="col-sm-8">
                      <multiselect
                          v-model="supplier"
                          :placeholder="$t('company.business_name')"
                          label="business_name"
                          track-by="id"
                          :options="suppliers"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getSuppliers($event)">
                      </multiselect>
                      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('purchases_invoices.supplier') }}.</span>
                      <span v-if="validation && validation.supplier_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.supplier_id[0] }}
                                    </span>
                    </div>
                    <div class="col-sm-4">
                      <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <b-card no-body class="mt-5">
              <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                  {{ $t('accounts') }}
                </b-button>
              </b-card-header>

              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body class="row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('accounts') }}</label>
                    <div class="input-group mb-3">
                      <multiselect
                          v-model="account"
                          @input="updateValue($event)"
                          :placeholder="$t('accounts')"
                          label="label"
                          track-by="id"
                          :options="accounts"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getAccounts($event)">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/accounting/chart-accounts/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('accounts') }}.</span>

                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="col-lg-6">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_invoices.purchase_code') }}</label>
                  <input type="text" v-model="data.purchase_code" disabled class="form-control" :class="validation && validation.purchase_code ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.purchase_code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.purchase_code[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_invoices.reference_number') }}</label>
                  <input type="text" v-model="data.reference_number" class="form-control" :class="validation && validation.reference_number ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.reference_number" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.reference_number[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_invoices.purchase_date') }}</label>
                  <input type="date" v-model="data.purchase_date" disabled class="form-control" :class="validation && validation.purchase_date ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.purchase_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.purchase_date[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('sales_invoices.issue_date') }}</label>
                  <input type="date" v-model="data.issue_date" disabled class="form-control" :class="validation && validation.issue_date ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.issue_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.issue_date[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_invoices.currency') }}</label>
                  <div class="input-group mb-3">
                    <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                      <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_invoices.inventory') }}<span class="text-danger">*</span></label>
                  <div class="input-group mb-3">
                    <multiselect v-model="inventory"
                                 :placeholder="$t('purchases_invoices.inventory')"
                                 label="name"
                                 track-by="id"
                                 :options="inventories"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.inventory_id[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_invoices.payment_terms') }}</label>
                  <div class="form-group row">
                    <div class="col-sm-10">
                      <input type="number" v-model="data.payment_terms" class="form-control" :class="validation && validation.payment_terms ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.payment_terms" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.payment_terms[0] }}
                                    </span>
                    </div>
                    <span class="col-sm-2">{{ $t('purchases_invoices.day_s') }}</span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('status') }}</label>
                  <select name="" id="status" v-model="data.status" disabled type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                    <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                  </select>
                  <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('is_apply_taxes_invoice') }}:</label>
                  <b-form-checkbox size="lg" :disabled="true" v-model="data.is_apply_taxes_invoice" name="check-button" switch></b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row mt-5">
          <div class="col-md-12">
            <hr>
          </div>
          <div class="col-md-12">
            <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
              <h6 class="my-auto text-white">{{ $t('purchases_invoices.items') }}</h6>
              <div class="d-flex">
                <import-excel-data
                    :btn-class="'btn btn-primary btn-sm'"
                    @data="readDataFromExcel"
                    :inner-id="'1'"
                    :placeholder="$t('import_data')"
                    :template-link="'template/templateRepeaterItems'"
                ></import-excel-data>
                <button type="button" class="btn btn-primary btn-sm mr-2 ml-2" @click="addItemRowToList">{{ $t('add_more') }}</button>

              </div>

            </div>
            <div class="table-responsive bg-white pb-5">
              <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
                <thead>
                <tr>
                  <th width="250px">{{ $t('items.item_details') }}</th>
                  <th width="300px">
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('purchases_invoices.item') }}</span>
                      <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                        <b-icon icon="plus"></b-icon>
                      </a>
                    </div>
                  </th>
                  <!--                        <th>{{ $t('purchases_invoices.description') }}</th>-->
                  <th width="140px">{{ $t('purchases_invoices.unit_price') }}</th>
                  <th width="140px">{{ $t('purchases_invoices.unit') }}</th>
                  <th width="140px">{{ $t('purchases_invoices.qty') }}</th>
                  <th width="140px" v-if="isApplyTaxesInvoice">{{ $t('purchases_invoices.tax') }}</th>
                  <!--                        <th>{{ $t('purchases_invoices.discount_type') }}</th>-->
                  <!--                        <th>{{ $t('purchases_invoices.discount_value') }}</th>-->
                  <th width="140px">{{ $t('discount') }}</th>
                  <th width="80px" v-if="isApplyTaxesInvoice">{{ $t('purchases_invoices.amount_tax') }}</th>
                  <th width="80px">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                  <th width="80px">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                  <th width="10px"></th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="(row, index) in items_list" :key="index" :class="getClassValidationRepeaterByIndex(index)">
                  <td>
                    <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                      <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                      <div class="pl-2 pr-2 text-dark">
                        <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                        <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                        <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                        <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                        <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.sum_available_qty }}</p>
                      </div>
                    </a>
                  </td>
                  <td>
                    <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('purchases_invoices.item')">
                      <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                        <option value="name" selected>{{ $t('items.name') }}</option>
                        <option value="sku_code">{{ $t('items.sku_code') }}</option>
                      </select>
                      <multiselect v-model="row.item"
                                   :placeholder="$t('purchases_invoices.item')"
                                   :label="row.f_by? row.f_by:'name'"
                                   track-by="id"
                                   :options="items"
                                   :multiple="false"
                                   @input="selectItem(index)"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :internal-search="false"
                                   @search-change="getItems(row.f_by, $event)">
                      </multiselect>
                    </div>
                    <input v-model="row.description" type="text" class="form-control mt-3" :placeholder="$t('purchases_invoices.description')">
                    <span v-if="validation && validation[`items_list.${index}.item`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.item`][0] }}
                          </span>
                  </td>
                  <td>
                    <input v-model="row.unit_price" @input="setValue(index)" type="number" class="form-control" :class="validation && validation[`items_list.${index}.unit_price`] ? 'is-invalid' : ''">
                    <span v-if="validation && validation[`items_list.${index}.unit_price`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.unit_price`][0] }}
                          </span>
                  </td>

                  <td>
                    <multiselect v-model="row.unit" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('sales_invoices.unit')"
                                 :placeholder="$t('sales_invoices.unit')"
                                 label="name"
                                 track-by="id"
                                 :options="(row.item && row.item.units) ? row.item.units:[]"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @input="setValueUnit(index)"
                                 :class="validation && validation[`items_list.${index}.unit`] ? 'is-invalid' : ''"
                                 >
                    </multiselect>
                    <span v-if="validation && validation[`items_list.${index}.unit`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.unit`][0] }}
                          </span>

                    <!--                          <input v-model="row.units_number" v-if="row.unit && row.unit.parent_id" min="0" @input="setValue(index)" type="number" class="form-control mt-3">-->
                    <input v-model="row.units_number" v-if="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" min="0" @input="setValue(index)" type="number" class="form-control mt-3">

                  </td>

                  <td>
                    <input v-model="row.qty" :disabled="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" @input="setValue(index)" type="number" class="form-control">
                    <span v-if="validation && validation[`items_list.${index}.qty`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.qty`][0] }}
                          </span>
                  </td>

                  <!--                        <td><input v-model="row.tax_percentage"   min="0" :disabled="!(row.item && row.item.taxes  && row.item.taxes.length > 0)" @input="setValue(index)" type="number" class="form-control"></td>-->
                  <td v-if="isApplyTaxesInvoice">
                    <input v-for="(row_tax, index_tax) in row.tax_applied" v-model="row_tax.percentage" :key="'index_tax'+index_tax" min="0" :disabled="true" @input="setValue(index)" type="number" class="form-control" style="margin-bottom: 5px;">
                  </td>
                  <td>
                    <div class="d-flex">
                      <select name="" id="discount_type" @change="setValue(index)" v-model="row.discount_type" class="custom-select">
                        <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <input v-model="row.discount_value" @input="setValue(index)" type="number" class="form-control">
                    </div>
                  </td>
                  <td v-if="isApplyTaxesInvoice">{{ row.amount_tax }}</td>
                  <td>{{ row.subtotal_before_discount && row.subtotal_before_discount > 0 ? parseToShortFloat(row.subtotal_before_discount) : 0 }}</td>
                  <td>{{ row.subtotal_after_discount && row.subtotal_after_discount > 0 ? parseToShortFloat(row.subtotal_after_discount) : 0 }}</td>
                  <td>
                    <v-icon style="color: #dc3545;" class="m-40-4" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                  <th colspan="2"><span>{{ data.total_amount_before_discount && data.total_amount_before_discount > 0 ? parseToShortFloat(data.total_amount_before_discount) : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                  <th colspan="2"><span>{{ data.total_amount_after_discount && data.total_amount_after_discount > 0 ? parseToShortFloat(data.total_amount_after_discount) : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>

                <!--                      <tr>-->
                <!--                        <td colspan="6"></td>-->
                <!--                        <th colspan="2">{{ $t('purchases_invoices.items_total') }}</th>-->
                <!--                        <th colspan="2"><span>{{ items_total ? items_total: '0' }} {{currency_code}}</span></th>-->
                <!--                        <th></th>-->
                <!--                      </tr>-->
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('purchases_invoices.invoice_total_discount') }}</th>
                  <th colspan="2"><span>{{ data.invoice_total_discount ? data.invoice_total_discount : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_after_shipping') }}</th>
                  <th colspan="2"><span>{{ data.shipping_fees ? data.shipping_fees : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr v-if="isApplyTaxesInvoice">
                  <td class="border-0" colspan="6"></td>
                  <th colspan="2">{{ $t('purchases_invoices.total_tax') }}</th>
                  <th colspan="2"><span>{{ data.invoice_total_taxes ? data.invoice_total_taxes : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('purchases_invoices.total') }}</th>
                  <th colspan="2"><span>{{ data.invoice_total ? data.invoice_total : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <b-tabs content-class="mt-3" class="nav-custom-link">
            <b-tab :title="$t('delivery_information')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('purchases_invoices.shipping_fees') }}</label>
                    <input type="number" v-model="data.shipping_fees" class="form-control" @input="eventShippingFees" :class="validation && validation.shipping_fees ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.shipping_fees" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_fees[0] }}
                            </span>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('notes')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('purchases_invoices.notes') }}</label>
                    <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                    <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('purchases_invoices.attachment') }}</label>
                    <div class="form-group">
                      <upload-file
                          @file="listenerAttachment"
                          :inner-id="'attachment'"
                          :placeholder="$t('purchases_invoices.attachment')"
                          :upload="dir_upload"
                          :start-link="'base'"
                          v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                      </upload-file>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>


        </div>
      </b-tab>

      <b-tab v-if="idEditing" :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">
        <div class="card card-custom mt-5">
          <div class="card-body">
            <br>
            <activity-log :inner-key="key" :id="idEditing"></activity-log>
          </div>
        </div>
      </b-tab>
      <b-tab v-if="idEditing" :title="$t('stock_change_control_log')" @click="toggleTab('statistics_log_inventory')">
        <div class="card card-custom mt-5">
          <div class="card-body">
            <br>
            <statistics-log-inventory :inner-key="key" :id="idEditing"></statistics-log-inventory>
          </div>
        </div>
      </b-tab>

    </b-tabs>

    <div class="pl-0 pr-0" v-if="tab_name != 'activity_log' && tab_name != 'statistics_log_inventory'">
      <div class="row">
        <div class="col-lg-6 mt-10" v-if="!is_confirmed">
          <button class="btn btn-primary mr-2" @click="save($event, 1)">{{ $t('save') }}</button>
          <button v-if="!isEditing" class="btn btn-primary mr-2" @click="save($event, 2)">{{ $t('save_and_create_new') }}</button>
          <button v-if="!isEditing" class="btn btn-primary mr-2" @click="save($event, 3)">{{ $t('save_and_redirect_to_main_page') }}</button>
<!--          <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>-->
        </div>
      </div>
    </div>

    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('suppliers.add_new_supplier')">
      <supplier-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewSupplier"></supplier-form>
    </b-modal>
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import SupplierForm from "@/view/content/forms/SupplierForm";
import ItemForm from "@/view/content/forms/ItemForm";
import SweetAlert2 from "sweetalert2";
import {mapGetters, mapState} from "vuex";
import ActivityLog from "@/view/content/widgets/details/ActivityLog";
import StatisticsLogInventory from "@/view/content/widgets/details/StatisticsLogInventory";
import dataTypeNumbers from '@/core/config/mix/dataTypeNumbers';
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "form-purchases-invoices",
  components: {'supplier-form': SupplierForm, 'item-form': ItemForm, 'activity-log': ActivityLog, 'statistics-log-inventory': StatisticsLogInventory},
  data() {
    return {
      mainRoute: 'purchases/purchases-invoices',
      subMainRoute: 'purchases/purchases-invoice',
      mainRouteDependency: 'base/dependency',
      mainRouteForPurchaseRequest: 'purchases/purchases-requests',
      mainRouteForPurchaseQuotations: 'purchases/purchases_quotations',
      mainTaxSettingsRoute: 'purchases/purchases-settings',
      dir_upload: 'purchases',

      data: {
        title: "",
        supplier_id: "",
        purchase_code: "",
        invoice_subtotal: 0,
        invoice_total: 0,
        invoice_total_discount: 0,
        invoice_total_shipping: 0,
        invoice_total_taxes: 0,
        total_amount_before_discount: 0,
        total_amount_after_discount: 0,
        adjustment_value: 0,
        deposite_value: 0,

        purchase_date: "",
        issue_date: "",
        payment_terms: "",
        currency_id: "",
        shipping_fees: "",
        inventory_id: "",
        attachment: "",
        notes: "",
        status: 1,
        branch_id: null,
        account_id: null,
        exchange_rate: null,
        reference_number: null,
        apply_tax_rules: [],
        is_apply_taxes_invoice: false,
        total_out_tax: 0,
        total_tax_type_val: [],

      },
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: this.$route.params.id ? true : false,
      reloadUploadAttachment: true,
      attachment_url: '',
      sum_invoice_total: 0,

      suppliers: [],
      currencies: [],
      inventories: [],
      inventory: null,
      branches: [],
      branch: null,
      supplier: null,
      items: [],
      taxes: [],
      status_list: [],
      accounts: [],
      account: [],
      isDisabled: false,
      tax_default: null,
      unit_default: null,
      validation: [],
      items_list_form: {id: null, unique_key: null, item: null, description: null, unit_price: null, qty: null, tax: null, discount_type: 1, discount_value: null, subtotal_before_tax: null, subtotal: null, amount_tax: null, f_by: 'name', tax_percentage: null, unit: null, units_number: null, tax_applied: [], subtotal_before_discount: null, subtotal_after_discount: null, subtotal_after_tax: null, amount_excluded_tax: 0, amount_included_tax: 0, data_validation: []},
      items_list: [],
      items_total: 0,
      total_tax: 0,
      total: 0,
      total_discount: 0,
      discount_types: [
        {id: 1, name: '-'},
        {id: 2, name: '%'},
        {id: 3, name: this.$t('discount_types.value')},
      ],
      add_qty_parent: 0,
      purchase_request_id: this.$route.params.purchase_request_id ? this.$route.params.purchase_request_id : null,
      quotation_type: this.$route.params.quotation_type ? this.$route.params.quotation_type : null,
      tab_name: 'basic_information',
      key: 'PurchaseInvoice',
      is_request_running: false,
      units: [],
      currency_code: null,

      tax_settings: [],
      taxes_rules_for_tax_setting: [],
      is_allow_tax_calc: false,
      repeater_validation: [],
      is_confirmed: false,

    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),
    isApplyTaxesInvoice() {
      let _status = this.data.is_apply_taxes_invoice;
      return _status;
    },
  },
  watch: {
    account: function (val) {
      if (val) {
        this.data.account_id = val.id;
      }
    },
    supplier: function (val) {
      if (val) {
        this.data.supplier_id = val.id;
      }
    },
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      }
    },
    'data.status': function (val) {
      if (!val) {
        this.data.status = 1;
      }
    },
    // items_list: {
    //   handler(val) {
    //     if (val.length > 0) {
    //       this.items_total = this.$_.sumBy(this.items_list, (row) => {
    //         return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
    //       });
    //       this.items_total = this.items_total ? parseFloat(this.items_total) : 0;
    //
    //       this.total_tax = this.$_.sumBy(this.items_list, (row) => {
    //         return row.amount_tax ? parseFloat(row.amount_tax) : 0;
    //       });
    //
    //       this.total_discount = this.$_.sumBy(this.items_list, (row) => {
    //         if (!row.discount_type || row.discount_type == 1) {
    //           return 0;
    //         }
    //         let _subtotal = ((row.unit_price ? row.unit_price : 0) * (row.qty ? row.qty : 0));
    //
    //         // let _subtotal = row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
    //         return ((row.discount_type == 2 && row.discount_value) ? (parseFloat(row.discount_value) / 100) * _subtotal : (row.discount_value ? parseFloat(row.discount_value) : 0));
    //       });
    //
    //       this.total_tax = this.total_tax ? parseFloat(this.total_tax) : 0;
    //
    //       // this.total = this.$_.sumBy(this.items_list, (row) => {
    //       //   return row.subtotal ? parseFloat(row.subtotal) : 0;
    //       // });
    //       this.total = this.items_total + this.total_tax - this.total_discount;
    //
    //       /**
    //        * sum amount before discount
    //        */
    //       this.data.total_amount_before_discount = this.$_.sumBy(this.items_list, (row) => {
    //         return (row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0);
    //       });
    //       /**
    //        * sum amount after discount
    //        */
    //       this.data.total_amount_after_discount = this.$_.sumBy(this.items_list, (row) => {
    //         return (row.subtotal_after_discount ? parseFloat(row.subtotal_after_discount) : 0);
    //       });
    //
    //       this.data.invoice_subtotal = this.items_total;
    //       this.data.invoice_total_taxes = this.total_tax;
    //       this.data.invoice_total = this.total;
    //     }
    //   },
    //   deep: true
    // },
    "data.currency_id": function (val) {

      if (val) {
        let _currency = this.currencies.find((row) => row.id == val);
        if (_currency) {
          this.currency_code = _currency.code;
        } else {
          this.currency_code = null;
        }
      } else {
        this.currency_code = null;
      }

      // if (val) {
      //   this.getExchangeRate(val);
      // } else {
      //   this.data.exchange_rate = null;
      // }
    },

  },
  methods: {
    ...dataTypeNumbers,
    ...timeZoneStructure,
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    readDataFromExcel(event) {
      if (event.length > 0) {
        let _sku_codes = [];
        let _data = [];
        event.forEach((row) => {
          let _sku_code = row.sku_code;

          if (_sku_code) {
            _sku_codes.push(_sku_code);
          }
          let _quantity = (row.quantity ? parseInt(row.quantity) : 0);
          _quantity = (!isNaN(_quantity) ? _quantity : 0);
          let _price = (row.price ? parseFloat(row.price) : 0);
          _price = (!isNaN(_price) ? _price : 0);

          let _subtotal = _quantity * _price;
          _data.push({f_by: 'sku_code', id: null, item: null, sku_code: row.sku_code, description: null, unit_price: _price, qty: _quantity, tax: null, discount_type: 1, discount_value: null, subtotal: _subtotal, tax_percentage: null, unit: null, units_number: null})
        });

        this.getItemsByIds(_sku_codes).then((response) => {

          this.items_list = _data.filter((row) => {
            row.item = response.find((da) => da.sku_code == row.sku_code);

            return row.item != null;
          });

          this.items_list.forEach((row, index) => {
            this.setValue(index);
          });
        });
      }

    },
    async getItemsByIds(sku_codes = []) {
      if (sku_codes && sku_codes.length > 0)
        return await ApiService.get(this.mainRouteDependency + "/itemsByIds", {params: {sku_codes: sku_codes, item_type: 1}}).then((response) => {
          return response.data.data;
        });
    },
    redirectActionAfterSave(_action, id){
      switch (_action){
        case 1:
          this.isEditing = true;
          this.$router.push({name: 'purchases-invoices.edit', params: {id: id}});
          break;
        case 2:
          this.$router.push({query: {_pgid: Date.now()}});
          // this.$router.push({name: 'purchases-invoices.create'});
          break;
        case 3:
          this.$router.push({name: 'purchases-invoices.index'});
          break;
        default:
          this.isEditing = true;
          this.$router.push({name: 'purchases-invoices.edit', params: {id: id}});
          break;
      }
    },
    save(event, _action) {
      if (this.validationQTYChecking()) {
        event.preventDefault();
        return false;
      } else {
        if (this.is_request_running) {
          return false;
        } else {
          this.is_request_running = true;
        }
        // this.$confirmAlertWithTwoFunc();
        let ids = [];
        this.items_list.forEach((it) => {
          if (it.item)
            ids.push(it.item.id);
        });

        ApiService.get(this.subMainRoute + "/check_parent_items", {params: {ids: ids}}).then((response) => {
          if (response.data.message) {
            SweetAlert2.fire({
              title: response.data.message,
              showCancelButton: true,
              confirmButtonText: this.$t('yes'),
              cancelButtonText: this.$t('no'),
              allowOutsideClick: false,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.add_qty_parent = 1;
                this.createOrUpdate(_action);
              } else {
                this.add_qty_parent = 0;
                this.createOrUpdate(_action);
              }
            });
          } else {
            this.createOrUpdate(_action);
          }
        });
      }
    },

    createOrUpdate(_action) {

      if (this.isEditing) {
        this.update();
      } else {
        this.create(_action);
      }


    },
    // validationQTYChecking(_status = true) {
    //   let _validation_status = false;
    //   this.validation = [];
    //   this.items_list.forEach((row, index) => {
    //     if (parseFloat(row.qty) <= 0) {
    //       this.validation[`items_list.${index}.qty`] = [];
    //       this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
    //       _validation_status = true;
    //     }else if(!row.qty && _status){
    //       this.validation[`items_list.${index}.qty`] = [];
    //       this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
    //       _validation_status = true;
    //
    //     }
    //   });
    //   if (_validation_status){
    //     let _items = [...this.items_list];
    //     this.items_list = [];
    //     this.items_list = _items;
    //     if (_status)
    //       this.$errorAlertMessage(this.$t('zero_quantities_cannot_be_accepted'));
    //   }
    //   return _validation_status;
    //
    // },
    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
            // return 'tr-validation-error';
          return 'border-validation';
      }
      return '';
    },
    validationQTYChecking(_status = true) {
      let _main_validation_status = false;
      this.validation = [];
      this.items_list.forEach((row, index) => {
        let _validation_status = false;
        if (parseFloat(row.qty) <= 0) {
          this.validation[`items_list.${index}.qty`] = [];
          this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
          _validation_status = true;
        }else if(!row.qty && _status){
          this.validation[`items_list.${index}.qty`] = [];
          this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
          _validation_status = true;
        }

        if (parseFloat(row.unit_price) <= 0) {
          this.validation[`items_list.${index}.unit_price`] = [];
          this.validation[`items_list.${index}.unit_price`][0] = this.$t('unit_price_must_be_greater_than_0');
          _validation_status = true;
        }else if(!row.unit_price && _status){
          this.validation[`items_list.${index}.unit_price`] = [];
          this.validation[`items_list.${index}.unit_price`][0] = this.$t('unit_price_must_be_greater_than_0');
          _validation_status = true;
        }
        if(!row.item && _status){
          this.validation[`items_list.${index}.item`] = [];
          this.validation[`items_list.${index}.item`][0] = this.$t('the_item_field_must_be_required');
          _validation_status = true;
        }

        if(!row.unit && _status){
          this.validation[`items_list.${index}.unit`] = [];
          this.validation[`items_list.${index}.unit`][0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }

        if (parseFloat(row.amount_tax) <= 0 && row.tax_applied.length > 0) {
          _validation_status = true;
        }else if(!row.amount_tax && _status && row.tax_applied.length > 0){
          _validation_status = true;
        }

        if (parseFloat(row.subtotal_before_discount) <= 0) {
          _validation_status = true;
        }else if(!row.subtotal_before_discount && _status){
          _validation_status = true;
        }

        if (_validation_status && _status){
          let _index = this.repeater_validation.indexOf(index);
          if (_index < 0) {
            this.repeater_validation.push(index);
          }
        }else{
          let _index = this.repeater_validation.indexOf(index);
          if (_index > -1) {
            this.repeater_validation.splice(_index, 1);
          }
        }

      });
      if (this.repeater_validation.length > 0){
        _main_validation_status = true;
      }
      if (_main_validation_status){
        let _items = [...this.items_list];
        this.items_list = [];
        this.items_list = _items;
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }
      return _main_validation_status;

    },
    refactorValidation(){
      this.validationQTYChecking(false);
    },
    create(_action) {
      ApiService.post(`${this.mainRoute}`, {
        items_list: this.items_list,
        add_qty_parent: this.add_qty_parent,
        // items_total: this.items_total,
        // total_tax: this.total_tax,
        // total: this.total,
        ...this.data,
      })
          .then((response) => {
            // this.$router.push({name: 'purchases-invoices.index'});
            this.validation = [];
            this.$successAlert(response.data.message);
            this.is_request_running = false;
            this.redirectActionAfterSave(_action, response.data.data.id);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
            this.is_request_running = false;
          });
    },


    update() {

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: this.items_list,
        add_qty_parent: this.add_qty_parent,
        // items_total: this.items_total,
        // total_tax: this.total_tax,
        // total: this.total,
        ...this.data,
      })
          .then((response) => {
            this.$router.push({name: 'purchases-invoices.index'});
            this.validation = [];
            this.$successAlert(response.data.message);
            this.is_request_running = false;
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
            this.is_request_running = false;
          });
    },

    async getData() {
      this.reloadUploadAttachment = false;
      await ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.title = response.data.data.title;
        this.data.supplier_id = response.data.data.supplier_id;
        this.supplier = response.data.data.supplier;
        this.data.purchase_code = response.data.data.purchase_code;
        this.data.reference_number = response.data.data.reference_number;
        this.data.purchase_date = response.data.data.purchase_date;
        this.data.payment_terms = response.data.data.payment_terms;
        this.data.currency_id = response.data.data.currency_id;
        this.data.shipping_fees = response.data.data.shipping_fees;
        this.data.inventory_id = response.data.data.inventory_id;
        this.inventory = response.data.data.inventory;
        this.data.attachment = response.data.data.attachment;
        this.data.notes = response.data.data.notes;
        this.data.status = response.data.data.status;
        this.is_confirmed = response.data.data.status == 2 ? true : false;
        this.attachment_url = response.data.data.attachment_url;
        this.items_list = [];
        response.data.data.items_list.forEach((row, index) => {
          this.items_list.push({...response.data.data.items_list[index]});
        });
        this.items_list = this.items_list.map((row)=>{
          row.unique_key = this.getUniqueKeyValue();
          return row;
        });
        this.data.account_id = response.data.data.account_id;
        this.account = response.data.data.account;

        this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
        this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;

        this.data.is_apply_taxes_invoice = response.data.data.is_apply_taxes_invoice;

        this.data.total_out_tax = response.data.data.total_out_tax ? response.data.data.total_out_tax : 0;
        this.data.total_tax_type_val = response.data.data.total_tax_type_val ? response.data.data.total_tax_type_val : [];

        if (response.data.data.account_id == null) {
          //     // get account_id from getDefaultAccoun function
          this.getDefaultAccount();
        }

        this.data.issue_date = response.data.data.issue_date;
        this.data.exchange_rate = response.data.data.exchange_rate;

        this.data.invoice_subtotal = response.data.data.invoice_subtotal;
        // this.total_discount = response.data.data.total_discount;
        this.data.invoice_total_discount = response.data.data.invoice_total_discount;
        this.data.invoice_total_taxes = response.data.data.invoice_total_taxes;
        this.data.invoice_total = response.data.data.invoice_total;


        if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
          this.addItemRowToList();
        }
        this.reloadUploadAttachment = true;
      });
    },

    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/3").then((response) => {
        this.data.purchase_code = response.data.data.code;
      });
    },
    async getCurrencies() {
      await ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    async getDefaultCurrency() {
      await ApiService.get(this.mainRouteDependency + "/default-currency").then((response) => {
        this.data.currency_id = response.data.data.id;
      });
    },

    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/status?type=purchase").then((response) => {
        this.status_list = response.data.data;
      });
    },
    async getInventories() {
      await ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    async getBranches() {
      await ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
        if (!this.idEditing) {
          this.branch = this.branches.find(option => option.is_default == 1);
        }
        // this.branch = this.user_personal_info.branch;
      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter, 'item_type': 1}}).then((response) => {
          this.items = response.data.data;
        });
    },

    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes?type=3`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },
    // getUnits(filter) {
    //   if (filter && filter.length >= 3) {
    //     ApiService.get(`${this.mainRouteDependency}/units`, {params: {filter: filter}}).then((response) => {
    //       this.units = response.data.data;
    //     });
    //   } else {
    //     this.units = [];
    //   }
    // },
    // getUnits() {
    //     ApiService.get(`${this.mainRouteDependency}/units`).then((response) => {
    //       this.units = response.data.data;
    //     });
    // },

    // loadOptions({searchQuery}){
    //         if (searchQuery && searchQuery.length >= 3)
    //             this.getAccounts(searchQuery);

    // },
    updateValue(value) {
      this.data.account_id = value;
    },

    addItemRowToList() {
      // this.items_list_form.tax = this.tax_default;
      this.items_list_form.unique_key = this.getUniqueKeyValue();
      // this.items_list_form.unit = this.unit_default;
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, unique_key: null, item: null, description: null, unit_price: null, qty: null, tax: null, discount_type: 1, discount_value: null, subtotal_before_tax: null, subtotal: null, amount_tax: null, f_by: 'name', tax_percentage: null, unit: null, units_number: null, tax_applied: [], subtotal_before_discount: null, subtotal_after_discount: null, subtotal_after_tax: null, amount_excluded_tax: 0,  amount_included_tax: 0, data_validation: []};
      this.refactorValidation();
      },
    getUniqueKeyValue() {
      return Math.floor(Math.random() * 1000000000);
    },
    removeItemRowFromList(index = 0) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
        if (this.validation[`items_list.${index}.qty`]){
          delete this.validation[`items_list.${index}.qty`];
        }
        this.refactorValidation();
      }
    },
    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },
    selectItem(index) {
      this.items_list[index].unit_price = this.items_list[index].item ? this.items_list[index].item.purchase_price : 0;

      this.items_list[index].tax_percentage = this.items_list[index].item ? this.items_list[index].item.total_taxes : 0;
      // this.items_list[index].tax_applied = (this.items_list[index].item && this.items_list[index].item.taxes) ? this.items_list[index].item.taxes : [];

      this.items_list[index].unit = null;
      if (this.items_list[index].item && this.items_list[index].item.units && this.items_list[index].item.units.length) {
        this.items_list[index].unit = this.items_list[index].item.units.find(row => row.is_main);
      }

      this.setValue(index);

      // this.items_list[index].unit_price = this.items_list[index].item ? this.items_list[index].item.purchase_price : 0;
      // this.items_list[index].tax_percentage = this.items_list[index].item ? this.items_list[index].item.total_taxes : 0;
      // this.setValue(index);
    },
    setValueUnit(index) {
      this.items_list[index].qty = 0;
      this.setValue(index);
    },
    setValue(index) {
      this.zeroForNegative();

      if (this.items_list[index].item && this.items_list[index].unit && this.items_list[index].item.unit) {
        if (this.items_list[index].item.unit.id != this.items_list[index].unit.id) {
          if (this.items_list[index].item.is_min) {
            this.items_list[index].qty = ((this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 1) * (this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 1)).toFixed(3);
          } else {
            this.items_list[index].qty = ((this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 1) / (this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 1)).toFixed(3);
          }
        } else {
          this.items_list[index].units_number = 1;
        }
      }


      let promise =  this.getItemTax(index);

      Promise.all([promise]).then((res) => {

        if (res[0] == undefined){
          return false;
        }
        index = this.items_list.findIndex(it => (it.unique_key == res[0]));

        let _tax = 0;
        let _tax_include_tax = 0;

        // if (this.items_list[index].unit) {
        //   if (this.items_list[index].unit.parent_id) {
        //     this.items_list[index].qty = (this.items_list[index].unit.sub_value_total ? this.items_list[index].unit.sub_value_total : 0) * (this.items_list[index].units_number ? this.items_list[index].units_number : 0);
        //   }else {
        //     this.items_list[index].units_number = 0;
        //     // this.items_list[index].qty = 0;
        //   }
        // }


        // if (this.items_list[index].item && this.items_list[index].unit && this.items_list[index].item.unit) {
        //   if (this.items_list[index].item.unit.id != this.items_list[index].unit.id) {
        //     if (this.items_list[index].item.unit.sub_value_total < this.items_list[index].unit.sub_value_total){
        //       this.items_list[index].qty = (this.items_list[index].unit.sub_value_total ? parseFloat(this.items_list[index].unit.sub_value_total) : 0) * (this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 0);
        //     }else{
        //       this.items_list[index].qty = (this.items_list[index].unit.sub_value_total ? parseFloat(this.items_list[index].unit.sub_value_total) : 0) / (this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 0);
        //     }
        //   } else {
        //     this.items_list[index].units_number = 0;
        //     // this.items_list[index].qty = 0;
        //   }
        // }


        let _subtotal = ((this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0));
        this.items_list[index].subtotal_before_tax = (_subtotal).toFixed(2);
        // this.items_list[index].subtotal_before_tax = (_subtotal - parseFloat(this.items_list[index].amount_included_tax)).toFixed(2);
        this.items_list[index].subtotal_after_tax = (_subtotal + parseFloat(this.items_list[index].amount_excluded_tax)).toFixed(2);

        let _discount = 0;
        if (this.items_list[index].discount_type == 1 || !this.items_list[index].discount_type) {
          _discount = 0;
        } else {
          _discount = ((this.items_list[index].discount_type == 2 && this.items_list[index].discount_value) ? (parseFloat(this.items_list[index].discount_value) / 100) * _subtotal : (this.items_list[index].discount_value ? parseFloat(this.items_list[index].discount_value) : 0));
        }
        // _subtotal = _subtotal - _discount;
        // let _tax = _subtotal * (this.items_list[index].tax_percentage ? parseFloat(this.items_list[index].tax_percentage) / 100 : 0);


        /**
         *
         * calc data subtotal before discount  = unit price * quantity
         * calc data subtotal after discount  = (unit price * quantity) - discount
         */
        this.items_list[index].subtotal_before_discount = _subtotal;
        this.items_list[index].subtotal_after_discount = _subtotal - _discount;

        /**
         * calculation for taxes
         */


        // this is used to get tax setting value
        // let _tax_rule_value = null;
        // let _tax_rule_id = (this.data.apply_tax_rules && this.data.apply_tax_rules[0]) ? this.data.apply_tax_rules[0].tax_rule_id : null;
        // if (_tax_rule_id) {
        //   let _tax_rule = this.taxes_rules_for_tax_setting.find((row) => row.id == _tax_rule_id);
        //   let _tax_rule_details = _tax_rule.tax_rule_details[0];
        //   _tax_rule_value = _tax_rule_details ? _tax_rule_details.value : null;
        // }


        // dependant on value calculate the tax
        // if (this.tax_settings && this.items_list[index].tax_applied.length) {
        //
        //
        //   let _tax_percentage_include_tax = this.items_list[index].tax_applied[0] ? this.items_list[index].tax_applied[0].include_tax : 0;
        //
        //
        //   let _tax_percentage_first = this.items_list[index].tax_applied[0] ? this.items_list[index].tax_applied[0].percentage : 0;
        //   let _tax_first = 0;
        //   if (_tax_percentage_include_tax == 0){
        //     _tax_first = this.items_list[index].unit_price - (this.items_list[index].unit_price / (1 + (_tax_percentage_first / 100)));
        //     _tax += _tax += (_tax_first * (this.items_list[index].qty ? this.items_list[index].qty : 1));
        //   }else {
        //     if (_tax_rule_value == 1) {
        //       _tax_first = _subtotal * (_tax_percentage_first / 100);
        //       _tax += _tax_first;
        //     } else if (_tax_rule_value == 2) {
        //       _tax_first = (_subtotal - _discount) * (_tax_percentage_first / 100);
        //       _tax += _tax_first;
        //     }
        //   }
        //
        //   _subtotal = _subtotal - _tax_first;
        // }

        // this.items_list[index].amount_tax = (_tax).toFixed(2);
        this.items_list[index].subtotal = (parseFloat(this.items_list[index].subtotal_before_tax) + _tax).toFixed(2);

        this.calcItemListForTotal();
      })
    },
    eventShippingFees: _.debounce(function () {
      this.calcItemListForTotal();
    }, 100),
    /**
     * used to calc sumation totaly
     */
    calcItemListForTotal() {
      if (this.items_list.length > 0) {

        // this.data.invoice_subtotal = this.$_.sumBy(this.items_list, (row) => {
        //   return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
        // });

        /**
         * sum amount before discount
         */
        this.data.total_amount_before_discount = this.$_.sumBy(this.items_list, (row) => {
          return (row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0);
        });
        /**
         * sum amount after discount
         */
        this.data.total_amount_after_discount = this.$_.sumBy(this.items_list, (row) => {
          return (row.subtotal_after_discount ? parseFloat(row.subtotal_after_discount) : 0);
        });

        this.data.invoice_subtotal = this.$_.sumBy(this.items_list, (row) => {
          return ((row.unit_price ? row.unit_price : 0) * (row.qty ? row.qty : 0));
        });
        this.data.invoice_total = this.$_.sumBy(this.items_list, (row) => {
          return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
        });

        let _discount_value = this.$_.sumBy(this.items_list, (row) => {
          if (!row.discount_type || row.discount_type == 1) {
            return 0;
          }
          // let _subtotal = row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
          let _subtotal = ((row.unit_price ? row.unit_price : 0) * (row.qty ? row.qty : 0));
          return ((row.discount_type == 2 && row.discount_value) ? (parseFloat(row.discount_value) / 100) * _subtotal : (row.discount_value ? parseFloat(row.discount_value) : 0));

        });

        this.data.invoice_total_discount = _discount_value;
        this.data.invoice_total_shipping = (this.data.shipping_fees ? parseFloat(this.data.shipping_fees) : 0);


        let _amount_excluded_tax = this.$_.sumBy(this.items_list, (row) => {
          return row.amount_excluded_tax ? parseFloat(row.amount_excluded_tax) : 0;
        });

        this.data.invoice_total_taxes = this.$_.sumBy(this.items_list, (row) => {
          return row.amount_tax ? parseFloat(row.amount_tax) : 0;
        });

        /***
         * summation all amount_tax if the tax include tax (1)
         * @private
         */
        // let _include_taxes = this.$_.sumBy(this.items_list, (row) => {
        //   if(row.tax_applied && row.tax_applied[0]){
        //     if(row.tax_applied[0].include_tax == 1){
        //       return row.amount_tax ? parseFloat(row.amount_tax) : 0;
        //     }
        //   }
        //   return 0;
        // });

        this.data.total_out_tax = this.$_.sumBy(this.items_list, (row) => {
          if (!row.tax_applied || row.tax_applied.length == 0) {
            if (row.unit_price && row.qty) {
              return parseFloat(row.unit_price) * parseFloat(row.qty);
            }
          }
          return 0;
        });

        this.setTotalTaxTypeVal();

        this.data.invoice_total_taxes = this.data.invoice_total_taxes ? parseFloat(this.data.invoice_total_taxes) : 0;
        let _shipping_fees = this.data.shipping_fees ? parseFloat(this.data.shipping_fees) : 0;
        this.data.invoice_total = (this.data.total_amount_before_discount + _shipping_fees - _discount_value + _amount_excluded_tax);

        this.data.total_out_tax = this.data.total_out_tax ? this.data.total_out_tax.toFixed(2) : 0;
        this.data.invoice_subtotal = this.data.invoice_subtotal ? this.data.invoice_subtotal.toFixed(2) : 0;
        this.data.invoice_total_discount = this.data.invoice_total_discount ? this.data.invoice_total_discount.toFixed(2) : 0;
        this.data.invoice_total_shipping = this.data.invoice_total_shipping ? this.data.invoice_total_shipping.toFixed(2) : 0;
        this.data.invoice_total_taxes = this.data.invoice_total_taxes ? this.data.invoice_total_taxes.toFixed(2) : 0;
        this.data.invoice_total = this.data.invoice_total ? this.data.invoice_total.toFixed(2) : 0;

        // if (this.idEditing && this.data.invoice_total !== null && this.data.invoice_total !== undefined
        //     && this.data.is_zero_invoice === false && !isNaN(this.data.invoice_total) && typeof this.data.invoice_total === 'number') {
        //   // Initialize sum_invoice_total
        //   this.sum_invoice_total = 0;
        //
        //   const formattedInvoiceTotal = parseFloat(this.data.invoice_total.toFixed(2));
        //
        //   // Check if formattedInvoiceTotal is greater than 0
        //   if (!isNaN(formattedInvoiceTotal) && formattedInvoiceTotal > 0) {
        //     this.sum_invoice_total = formattedInvoiceTotal;
        //   } else {
        //     // Calculate sum_invoice_total using other values if formattedInvoiceTotal is not greater than 0
        //     this.sum_invoice_total = (this.data.total_amount_before_discount + _shipping_fees - _discount_value + _include_taxes);
        //   }
        // } else {
        //   this.sum_invoice_total = this.$_.sumBy(this.items_list, (row) => {
        //     if(this.data.is_zero_invoice == 1){
        //       return 0
        //     }
        //     return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
        //   });
        // }

      }
    },
    setTotalTaxTypeVal() {
      this.data.total_tax_type_val = [];
      this.items_list.forEach((row) => {
        if (row.tax_applied && row.tax_applied.length > 0) {
          row.tax_applied.forEach((_row) => {
            let index_tax = this.data.total_tax_type_val.findIndex(it => _row.tax_id == it.tax_id);
            if (index_tax >= 0) {
              this.data.total_tax_type_val[index_tax].tax_value = (this.data.total_tax_type_val[index_tax].tax_value ? parseFloat(this.data.total_tax_type_val[index_tax].tax_value) : 0) + (row.tax_value ? parseFloat(row.tax_value) : 0);
              this.data.total_tax_type_val[index_tax].amount_applied = (this.data.total_tax_type_val[index_tax].amount_applied ? parseFloat(this.data.total_tax_type_val[index_tax].amount_applied) : 0) + (row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0);
            } else {
              this.data.total_tax_type_val.push({tax_id: _row.tax_id, tax_value: _row.tax_value, amount_applied: row.subtotal_before_tax});
            }
          });

        }
      });
    },
    zeroForNegative() {
      this.items_list = this.items_list.map((row) => {

        if (String(row.unit_price).length > 12) {
          row.unit_price = String(row.unit_price).slice(0, 12);
        }
        if (String(row.qty).length > 9) {
          row.qty = String(row.qty).slice(0, 9);
        }

        if (isNaN(row.unit_price)) {
          row.unit_price = 0;
        } else if (parseFloat(row.unit_price) < 0) {
          row.unit_price = 0;
        }
        if (isNaN(row.qty)) {
          if (parseFloat(row.qty) < 0) {
            row.qty = 0;
          }
        } else if (parseFloat(row.qty) < 0) {
          row.qty = 0;
        }
        if (isNaN(row.units_number)) {
          row.units_number = null;
        } else if (parseFloat(row.units_number) < 0) {
          row.units_number = 0;
        }


        if (isNaN(row.discount_value)) {
          row.discount_value = 0;
        }else if (parseFloat(row.discount_value) < 0) {
          row.discount_value = 0;
        }

        if (row.discount_type == 1) {
          row.discount_value = 0;
        } else if (row.discount_type == 2) {
          if (parseFloat(row.discount_value) > 100) {
            row.discount_value = 100;
          }
        } else if (row.discount_type == 3) {
          if (parseFloat(row.discount_value) > parseFloat(row.subtotal_before_discount)) {
            row.discount_value = row.subtotal_before_discount;
          }
        }

        if (isNaN(row.tax_percentage) || !Number(row.tax_percentage)) {
          row.tax_percentage = 0;
        }
        if (parseFloat(row.tax_percentage) < 0) {
          row.tax_percentage = 0;
        }
        return row;

      });
    },
    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    getDataAfterCreateNewSupplier(object) {
      let promise = this.getSuppliers();
      Promise.all([promise]).then(() => {
        this.data.supplier_id = object.id;
      })
    },
    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
          this.items_list[0].unit_price = object.purchase_price;
          this.items_list[0].tax_percentage = object.total_taxes ? object.total_taxes : 0;
          // this.items_list[0].tax = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
        }
      })
    },

    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts = response.data.data;
        });
    },

    // getAccounts() {
    //     ApiService.get(this.mainRouteDependency + `/account_parents`,{params:{checkDisabled: 1,key:'purchases'}}).then((response) => {
    //         this.accounts = response.data.data;
    //     });
    // },

    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/purchases`).then((response) => {
        this.account = response.data.data;
      });
    },
    // getExchangeRate(id) {
    //   ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
    //     this.data.exchange_rate = response.data.data.initial_val;
    //   });
    // },

    // async getDefaultTax() {
    //   await ApiService.get(`${this.mainRouteDependency}/default-tax`).then((response) => {
    //     this.tax_default = response.data.data;
    //   });
    // },
    // async getDefaultUnit() {
    //   await ApiService.get(`${this.mainRouteDependency}/default-unit`).then((response) => {
    //     this.unit_default = response.data.data;
    //   });
    // },


    async getDataForPurchaseRequest() {
      let _routeQuotation;
      _routeQuotation = this.quotation_type == 'purchase_quotation' ? this.mainRouteForPurchaseQuotations : this.mainRouteForPurchaseRequest
      if (this.purchase_request_id)
        await ApiService.get(`${_routeQuotation}/${this.purchase_request_id}`).then((response) => {
          this.data.title = response.data.data.title;
          this.data.attachment = response.data.data.attachment;
          this.data.branch_id = response.data.data.branch_id;
          this.branch = response.data.data.branch;
          this.data.notes = response.data.data.notes;
          this.supplier = response.data.data.supplier;
          this.data.supplier_id = response.data.data.supplier_id;

          this.attachment_url = response.data.data.attachment_url;
          this.items_list = [];

          if (response.data.data.items_list && response.data.data.items_list.length > 0) {
            response.data.data.items_list.forEach((row) => {
              this.items_list_form.item = row.item;
              this.items_list_form.unit_price = row.unit_price;
              this.items_list_form.qty = row.qty;
              this.addItemRowToList();
            });

            this.items_list.forEach((row, index) => {
              this.setValue(index);
            });


          } else {
            this.addItemRowToList();
          }
          this.reloadUploadAttachment = true;
        });
    },

    // async getDefaultUnit() {
    //   await ApiService.get(`${this.mainRouteDependency}/default-unit`).then((response) => {
    //     this.unit_default = response.data.data;
    //   });
    // },
    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;

        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
        this.data.inventory_id = response.data.data.inventory_id;
        this.inventory = response.data.data.inventory;
        // this.unit_default = response.data.data.unit;
      });
    },
    async getTaxesRulesForTaxSetting() {
      await ApiService.get(this.mainRouteDependency + "/taxes_rules_for_tax_setting/2").then((response) => {
        this.taxes_rules_for_tax_setting = response.data.data;
        response.data.data.forEach((row) => {
          if (row.auto_apply) {
            this.data.apply_tax_rules.push({tax_rule_id: row.id});
          }
        })

      });
    },
    getTaxSettings() {
      ApiService.get(this.mainTaxSettingsRoute).then((response) => {
        this.tax_settings = response.data.data;
        this.is_allow_tax_calc = response.data.data.is_apply_taxes_setting;
        this.data.is_apply_taxes_invoice = response.data.data.is_apply_taxes_setting;

      });
    },
    async getItemTax(index) {
      let unique_key = this.items_list[index].unique_key ? this.items_list[index].unique_key : null;
      if (!unique_key){
        this.items_list[index].unique_key = this.getUniqueKeyValue();
        unique_key = this.items_list[index].unique_key;
      }

      if (this.isApplyTaxesInvoice) {
        let item_id = this.items_list[index].item ? this.items_list[index].item.id : null;
        let quantity = this.items_list[index].qty ? this.items_list[index].qty : null;
        let unit_price = this.items_list[index].unit_price ? this.items_list[index].unit_price : null;
        let discount = this.items_list[index].discount_value ? this.items_list[index].discount_value : null;
        let discount_type = this.items_list[index].discount_type ? this.items_list[index].discount_type : null;

        if (item_id && quantity && unit_price && this.isApplyTaxesInvoice){
         return  await ApiService.get(`/v2/items/purchase/calculate-subtotal`,
              {
                params: {
                  item_id: item_id, quantity: quantity, sale_price: unit_price,
                  discount: discount, discount_type: discount_type, row_index: unique_key
                }
              }).then((response) => {
            let _inner_index = this.items_list.findIndex(it => (it.unique_key == response.data.data.row_index));

            this.items_list[_inner_index].amount_tax = this.parseToShortFloat(response.data.data.tax_total);
            this.items_list[_inner_index].tax_applied = response.data.data.applied_taxes ? response.data.data.applied_taxes : [];
            this.items_list[_inner_index].amount_excluded_tax = this.parseToShortFloat(response.data.data.excluded_taxes_total);
           this.items_list[_inner_index].amount_included_tax = response.data.data.included_taxes_total ? this.parseToShortFloat(response.data.data.included_taxes_total) : 0;

           let _subtotal_before_tax = this.parseToShortFloat(parseFloat(response.data.data.sub_total) - parseFloat(response.data.data.included_taxes_total))
           this.items_list[_inner_index].data_validation = {item_id: item_id , tax_total: response.data.data.tax_total, subtotal_before_tax: _subtotal_before_tax};

            this.calcItemListForTotal();

            return response.data.data.row_index;
          });
        }else{
          return unique_key;
        }

      }else{
        return unique_key;
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchases_invoices"), route: '/purchases/purchases-invoices'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);
    // this.getSuppliers();

    // this.getTaxesRulesForTaxSetting();
    this.getTaxSettings();
    this.getInventories();
    this.getCurrencies();
    // this.getUnits();
    // let promise2 = this.getDefaultCurrency();
    // let promise = this.getDefaultTax();
    // let promise2 = this.getDefaultUnit();
    // let promise5 = this.getDefaultUnit();

    // this.getTaxes();
    // this.getDefaultTax();
    // this.getItems();
    this.getStatusList();
    // this.getAccounts();
    this.getBranches();
    this.getDefaultAccount();
    if (this.idEditing) {
      this.getData();
    } else {
      this.getCode();
      let promise = this.defaultDataForUser();
      // this.data.purchase_date = new Date().toISOString().substr(0, 10);
      // this.data.issue_date = new Date().toISOString().substr(0, 10);
      this.getTimeZone().then((res)=>{
        this.data.purchase_date = res;
        this.data.issue_date = res;
      });
      if (this.items_list.length <= 0) {
        Promise.all([promise]).then(() => {
          if (this.purchase_request_id) {
            this.getDataForPurchaseRequest();
          } else {
            this.addItemRowToList();
          }
        });
      }
    }


  },
};
</script>
